var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-12 col-lg-9 col-md-12"},[_c('div',{staticClass:"form-group row tw-mb-3"},[_c('label',{staticClass:"col-md-5 form-col-label control-label required tw-text-right tw-p-2 tw-font-bold"},[_vm._v(_vm._s(_vm.$t("leaveRecord.leaveType"))+" ")]),_c('div',{staticClass:"col-md-7"},[_c('Select',{class:{
                    'ivu-form-item-error': _vm.errors.has('leave_type_id')
                },on:{"on-change":_vm.onLeaveType},model:{value:(_vm.model.leave_type_id),callback:function ($$v) {_vm.$set(_vm.model, "leave_type_id", _vm._n($$v))},expression:"model.leave_type_id"}},_vm._l((_vm.leaveTypes),function(leaveType,index){return _c('Option',{key:index,attrs:{"value":leaveType.leave_type_id}},[_vm._v(_vm._s(leaveType.leave_type_en)+" | "+_vm._s(leaveType.leave_type_kh)+" ")])}),1),(_vm.errors.has('leave_type_id'))?_c('div',{staticClass:"ivu-form-item-error tw-text-red-600"},[_vm._v(" "+_vm._s(_vm.errors.first("leave_type_id"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"form-group row tw-mb-3"},[_c('label',{staticClass:"col-md-5 form-col-label control-label required tw-text-right tw-p-2 tw-font-bold"},[_vm._v(_vm._s(_vm.$t("leaveRecord.employeeName"))+" ")]),_c('div',{staticClass:"col-md-7"},[_c('Select',{class:{
                    'ivu-form-item-error': _vm.errors.has('employee_id')
                },attrs:{"filterable":"","clearable":""},on:{"on-change":_vm.onChangeEmployee},model:{value:(_vm.model.employee_id),callback:function ($$v) {_vm.$set(_vm.model, "employee_id", _vm._n($$v))},expression:"model.employee_id"}},_vm._l((_vm.employeesFilter),function(employee,index){return _c('Option',{key:index,attrs:{"value":employee.employee_id}},[_vm._v(_vm._s(employee.employee_name_en)+" | "+_vm._s(employee.employee_name_kh)+" ")])}),1),(_vm.errors.has('employee_id'))?_c('div',{staticClass:"ivu-form-item-error tw-text-red-600"},[_vm._v(" "+_vm._s(_vm.errors.first("employee_id"))+" ")]):_vm._e()],1)]),(this.model.is_allow_sub_item)?_c('div',{staticClass:"form-group row tw-mb-3"},[_c('label',{staticClass:"col-md-5 form-col-label control-label required tw-text-right tw-p-2 tw-font-bold"},[_vm._v(_vm._s(_vm.$t("leaveRecord.leaveSubItem"))+" ")]),_c('div',{staticClass:"col-md-7"},[_c('Select',{class:{
                    'ivu-form-item-error': _vm.errors.has('leave_sub_item_id')
                },attrs:{"filterable":"","clearable":""},model:{value:(_vm.model.leave_sub_item_id),callback:function ($$v) {_vm.$set(_vm.model, "leave_sub_item_id", _vm._n($$v))},expression:"model.leave_sub_item_id"}},_vm._l((_vm.leaveTypeSubItems),function(leaveTypeSubItem,index){return _c('Option',{key:index,attrs:{"value":leaveTypeSubItem.leave_sub_item_id}},[_vm._v(_vm._s(leaveTypeSubItem.leave_sub_item)+" ")])}),1),(_vm.errors.has('leave_sub_item_id'))?_c('div',{staticClass:"ivu-form-item-error tw-text-red-600"},[_vm._v(" "+_vm._s(_vm.errors.first("leave_sub_item_id"))+" ")]):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"form-group row tw-mb-3"},[_c('label',{staticClass:"col-md-5 form-col-label control-label required tw-text-right tw-p-2 tw-font-bold"},[_vm._v(_vm._s(_vm.$t("leaveRecord.dateFrom"))+" ")]),_c('div',{staticClass:"col-md-7 tw-justify-between tw-flex"},[_c('div',{staticClass:"col-md-5"},[_c('DatePicker',{staticClass:"tw-w-full",class:{
                        'ivu-form-item-error': _vm.errors.has('date_from')
                    },attrs:{"value":_vm.model.date_from,"format":"dd-MM-yyyy","clearable":false,"options":_vm.dateFromOption,"type":"date","placeholder":"DD-MM-YYYY"},on:{"on-change":_vm.onChangeDateFrom}}),(_vm.errors.has('date_from'))?_c('div',{staticClass:"ivu-form-item-error tw-text-red-600"},[_vm._v(" "+_vm._s(_vm.errors.first("date_from"))+" ")]):_vm._e()],1),_c('label',{staticClass:"form-col-label control-label required tw-text-right tw-p-2 tw-font-bold tw-w-2/3"},[_vm._v(_vm._s(_vm.$t("leaveRecord.dateTo"))+" ")]),_c('div',{staticClass:"col-md-5"},[_c('DatePicker',{staticClass:"tw-w-full",class:{
                        'ivu-form-item-error': _vm.errors.has('date_to')
                    },attrs:{"value":_vm.model.date_to,"options":_vm.dateToOption,"clearable":false,"format":"dd-MM-yyyy","type":"date","placeholder":"DD-MM-YYYY","disabled":_vm.model.leave_type_id == 2},on:{"on-change":_vm.onChangeDateTo}}),(_vm.errors.has('date_to'))?_c('div',{staticClass:"ivu-form-item-error tw-text-red-600"},[_vm._v(" "+_vm._s(_vm.errors.first("date_to"))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"form-group row tw-mb-3"},[_c('label',{staticClass:"col-md-5 form-col-label control-label required tw-text-right tw-p-2 tw-font-bold"},[_vm._v(_vm._s(_vm.$t("leaveRecord.timeFrom"))+" ")]),_c('div',{staticClass:"col-md-7 tw-justify-between tw-flex"},[_c('div',{staticClass:"col-md-5"},[_c('Select',{class:{
                        'ivu-form-item-error': _vm.errors.has('time_from')
                    },attrs:{"disabled":_vm.model.leave_type_id == 2},on:{"on-change":_vm.onValueChange},model:{value:(_vm.model.time_from),callback:function ($$v) {_vm.$set(_vm.model, "time_from", $$v)},expression:"model.time_from"}},_vm._l((_vm.times),function(time,index){return _c('Option',{key:index,attrs:{"value":time.time_from}},[_vm._v(_vm._s(_vm.tConvert(time.time_from))+" ")])}),1),(_vm.errors.has('time_from'))?_c('div',{staticClass:"ivu-form-item-error tw-text-red-600"},[_vm._v(" "+_vm._s(_vm.errors.first("time_from"))+" ")]):_vm._e()],1),_c('label',{staticClass:"form-col-label control-label tw-text-right required tw-p-2 tw-font-bold tw-w-2/3"},[_vm._v(_vm._s(_vm.$t("leaveRecord.timeTo"))+" ")]),_c('div',{staticClass:"col-md-5"},[_c('Select',{class:{
                        'ivu-form-item-error': _vm.errors.has('time_to')
                    },attrs:{"disabled":_vm.model.leave_type_id == 2},on:{"on-change":_vm.onValueChange},model:{value:(_vm.model.time_to),callback:function ($$v) {_vm.$set(_vm.model, "time_to", $$v)},expression:"model.time_to"}},_vm._l((_vm.date_to),function(time,index){return _c('Option',{key:index,attrs:{"value":time.time_to}},[_vm._v(_vm._s(_vm.tConvert(time.time_to))+" ")])}),1),(_vm.errors.has('time_to'))?_c('div',{staticClass:"ivu-form-item-error tw-text-red-600"},[_vm._v(" "+_vm._s(_vm.errors.first("time_to"))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"form-group row tw-mb-3"},[_c('label',{staticClass:"col-md-5 form-col-label control-label required tw-text-right tw-p-2 tw-font-bold"},[_vm._v(_vm._s(_vm.$t("leaveRecord.reason"))+" ")]),_c('div',{staticClass:"col-md-7"},[_c('Input',{class:{
                    'ivu-form-item-error': _vm.errors.has('leave_reason')
                },attrs:{"type":"textarea"},model:{value:(_vm.model.leave_reason),callback:function ($$v) {_vm.$set(_vm.model, "leave_reason", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"model.leave_reason"}}),(_vm.errors.has('leave_reason'))?_c('div',{staticClass:"ivu-form-item-error tw-text-red-600"},[_vm._v(" "+_vm._s(_vm.errors.first("leave_reason"))+" ")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }